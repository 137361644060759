import { Typography } from "@mui/material";
import React from "react";
import { formatDate } from "../lib/date";
import { isNullOrEmpty } from "../lib/strings";

interface HeaderProperties {
   title?: string;
   endDateTime: Date;
}

export function Header(props: HeaderProperties) {

   const endDateTime = formatDate(props.endDateTime);
   const title = isNullOrEmpty(props.title) ? "Countdown to" : props.title;

   return (
      <>
         <Typography variant="h5" marginBottom="10px">{title}</Typography>
         <Typography variant="h5">{endDateTime}</Typography>
      </>
   );
}