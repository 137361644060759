import { MoreVert } from "@mui/icons-material";
import {
   CardContent,
   CardHeader,
   Divider,
   IconButton
} from "@mui/material";
import Card from "@mui/material/Card";
import React, { useState } from "react";
import 'dayjs/locale/en-gb';
import { Configure } from "./Configure";
import { Display } from "./Display";
import { Header } from "./Header";
import { ConfigurationData } from "../lib/configuration";

interface VisualizerProperties {
   title?: string;
   endDateTime: Date;
   showDateTimePicker: boolean;
   onChange: (data: ConfigurationData) => void;
}

export function Visualizer(props: VisualizerProperties) {
   const [open, setOpen] = useState(false);

   const handleOpenConfiguration = () => {
      setOpen(true);
   };

   const handleCloseConfiguration = () => {
      setOpen(false);
   };

   return (
      <>
         <Card
            variant="elevation"
            style={{ padding: "30px" }}
            sx={{ boxShadow: 20 }}
         >
            <CardHeader
               action={
                  <IconButton aria-label="configure" onClick={handleOpenConfiguration}>
                     <MoreVert />
                  </IconButton>
               }
               titleTypographyProps={{
                  variant: "h5",
               }}
               title={<Header title={props.title} endDateTime={props.endDateTime}/>}
            ></CardHeader>
            <Divider />
            <CardContent>
               <Display endDateTime={props.endDateTime} />
            </CardContent>
         </Card>
         <Configure open={open}
               title={props.title}
               endDateTime={props.endDateTime} 
               onChange={props.onChange} 
               onClose={handleCloseConfiguration}
               />
      </>
   );
}
