import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Home } from "./controls/Home";

function App() {
   return (
      <Routes>
         <Route index path="/end/:end" element={<Home />} />
         {/* <Route path="/" element={<Home />} /> */}
         <Route path="*" element={<Home />} />
      </Routes>
   );
}

export default App;
