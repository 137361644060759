import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   TextField,
} from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import { getSupportedLocale } from "../lib/locale";
import { ConfigurationData } from "../lib/configuration";

interface ConfigureProperties {
   title?: string;
   endDateTime: Date;
   open: boolean;
   onChange: (data: ConfigurationData) => void;
   onClose: () => void;
}

export function Configure(props: ConfigureProperties) {
   const [value, setValue] = useState<Dayjs | null>(
      dayjs(props.endDateTime),
   );   
   const [title, setTitle] = useState(props.title ?? "");
   const [locale] = useState<string>(getSupportedLocale(navigator.languages, navigator.language));

   useEffect(() => {
      setTitle(props.title ?? "");
      setValue(dayjs(props.endDateTime));
   }, [props])

   const handleAccept = () => {
      if(value != null) {
         const newDate = value.toDate();
         props.onChange({
            title: title,
            endDateTime: newDate
         });
      }
      handleClose();
   };
   
   const handleClose = () => {
      props.onClose();
   };

   const handleTitleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(ev.target.value ?? "");
   };

   const handleDateTimeChange = (newValue: Dayjs | null) => {
      setValue(newValue);
   };

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
         <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Configure</DialogTitle>
            <DialogContent>
               <DialogContentText >
                  Configure the date and time for this countdown
               </DialogContentText>
               <TextField 
                  value={title} 
                  label="Title" 
                  variant="standard" 
                  fullWidth 
                  autoFocus
                  margin="dense" 
                  onChange={handleTitleChange}/>
               <Box height="10px" />
               <DateTimePicker
                  value={value}
                  onChange={handleDateTimeChange}
                  renderInput={(params) => (
                     <TextField
                        {...params}
                        fullWidth
                        variant="standard"
                        margin="dense"
                     />
                  )}
               />
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose}>Cancel</Button>
               <Button onClick={handleAccept}>OK</Button>
            </DialogActions>
         </Dialog>
      </LocalizationProvider>
   );
}
