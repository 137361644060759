import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDays } from "../lib/date";

const EXPIRED = "EXPIRED";
const CALCULATING = "CALCULATING";

interface DisplayProperties {
   endDateTime: Date;
}

export function Display(props: DisplayProperties) {
   const [days, setDays] = useState(CALCULATING);
   const [workDays, setWorkDays] = useState("");

   useEffect(() => {
      setDays(CALCULATING);
      setWorkDays("");

      const countDownDate = props.endDateTime.getTime();

      const timer = setInterval(() => {
         const now = new Date().getTime();
         if(countDownDate >= now) {
            const d = getDays(now, countDownDate);

            setDays(
               d.days + "d " + d.hours + "h " + d.minutes + "m " + d.seconds + "s"
            );
            setWorkDays(d.workdays + " work days");
         } else {
            setDays(EXPIRED);
            setWorkDays("");   
         }
      }, 1000);

      return () => {
         clearInterval(timer);
         setDays(EXPIRED);
         setWorkDays("");
      };
   }, [props.endDateTime]);

   const foreground = days === CALCULATING ? "lightgray" : "black";

   return (
      <>
         <Typography variant="h4" color={foreground}>{days}</Typography>
         <Typography variant="h4" color={foreground}>{workDays}</Typography>
      </>
   );
}