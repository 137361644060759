import { ConfigurationData } from "./configuration";

const Key: string = "items";

export function load(): ConfigurationData | undefined {
   const json = localStorage.getItem(Key);
   const items = json != null ? JSON.parse(json) as [ConfigurationData] : undefined;
   if(items != null)
      return items[0];

   // check old storage mechanism can be removed once enough time has passed
   const old = localStorage.getItem("endDateTime");
   if(old != null)
      return {
         endDateTime: new Date(old)
      };
   
   return undefined;
}

export function save(data: ConfigurationData): void {

   if(data == null)
      return;

   // delete the old store can be removed once enough time has passed
   localStorage.removeItem("endDateTime");

   // in case we move to multiple countdowns
   const items = [data];
   localStorage.setItem(Key, JSON.stringify(items));
}