import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toDate } from "../lib/date";
import { load, save } from "../lib/store";
import { ConfigurationData } from "../lib/configuration";
import { Visualizer } from "./Visualizer";

// last day of the year as the default
const DefaultEndDateTime: Date = new Date(
   new Date().getFullYear(), 11, 31, 23,59
);

export function Home() {
   const { end } = useParams();

   const [state, setState] = useState<ConfigurationData>({
      title: undefined,
      endDateTime: DefaultEndDateTime
   });
   const [open, setOpen] = useState(false);

   useEffect(() => {
      if (end == null) {
         const data = load();
         if (data != null && data.endDateTime != null) {
            setState({
               title: data.title,
               endDateTime: new Date(data.endDateTime!)
            });
         } else {
            setOpen(true);
         }
      }
   }, [end]);

   function onConfigurationChanged(data: ConfigurationData) {      
      if(data != null) {
         const configurationData = {
            title: data.title,
            endDateTime: data.endDateTime
         }
         save(configurationData);
         setState(configurationData);
      }
   }
    
   const queryEndDateTime = toDate(end);
   const endDateTime = new Date(queryEndDateTime ?? state.endDateTime);
   const title = state.title;

   return (
      <div
         className="App"
         style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "lightgray",
         }}
      >
         <Visualizer 
            title={title}
            endDateTime={endDateTime} 
            showDateTimePicker={open} 
            onChange={onConfigurationChanged} />
      </div>
   );
}
