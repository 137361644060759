
function dateDifference(from: any, to: any) {
   const start = new Date(from);
   const end = new Date(to);
   let totalBusinessDays = 0;

   start.setHours(0, 0, 0, 0);
   end.setHours(0, 0, 0, 0);

   const current = new Date(start);
   current.setDate(current.getDate() + 1);
   let day;
   while (current <= end) {
      day = current.getDay();
      if (day >= 1 && day <= 5) {
         ++totalBusinessDays;
      }
      current.setDate(current.getDate() + 1);
   }

   return [to - from, totalBusinessDays];
}

function padTo2Digits(num: number): string {
   return num.toString().padStart(2, "0");
}


export function getDays(now: any, date: any) {
   const dates = dateDifference(now, date);

   const distance = dates[0];

   const days = Math.floor(distance / (1000 * 60 * 60 * 24));
   const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
   );
   const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
   const seconds = Math.floor((distance % (1000 * 60)) / 1000);

   const workdays = dates[1];

   return { days, hours, minutes, seconds, workdays };
}

// Sat Dec 31 2022 12:00
export function formatDate(date: Date): string {
   const day = new Date(date)
      .toLocaleString(undefined, { weekday: "long" })
      .substring(0, 3);
   const month = new Date(date)
      .toLocaleString(undefined, { month: "long" })
      .substring(0, 3);

   return (
      [day, padTo2Digits(date.getDate()), month, date.getFullYear()].join(" ") +
      " " +
      [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(":")
   );
}

export function toDate(value?: string): number | undefined {
   if (value == null) return undefined;

   const date = Date.parse(value);
   return isNaN(date) ? undefined : date;
}
